@import "../../sass/utils/index";

.option-text {
  font-size: 12pt;
  font-weight: $regular;
  @include desktop {
    font-size: 16pt;
  }
}

.radio-btn-group {
  display: flex;
  margin-bottom: 5px;
  margin-right: 25px;
}

.radio-btn {
  width: 20px;
  height: 20px;
  border: 1px solid $black;
  margin-top: auto;
  margin-right: 10px;
  border-radius: 35px;
  margin-bottom: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.checked .dot {
  width: 12px;
  height: 12px;
  background-color: #0a0a0a;
  border-radius: 17px;
}
