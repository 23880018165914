@import "../../../sass/utils/index";

.header {
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: $white;
  position: fixed;
  height: 80px;
} 

.scrollable {
  z-index: 1;
}

.header-content {
  display: flex;
  flex-direction: row;
  padding: 20px;
  padding-bottom: 0;
  width: 100%;
  border-bottom: 1px solid $black;
  justify-content: space-between;
  @include desktop {
    margin: 0 auto;
    max-width: 1200px;
    justify-content: space-between;
  }
}

.menu-button {
  border: none;
}

.display-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 80px;
  width: 135px;
  background-color: rgba(0, 0, 0, 0.4);
  visibility: visible;
  opacity: 1;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  transition-delay: 0s;
  transition: visibility 0s, opacity 0.5s linear;
  &.hide {
    visibility: hidden;
    opacity: 0;
  }
  @include desktop {
    visibility: hidden;
  }
}
.mobile-menu {
  display: block;
  @include desktop {
    display: none;
  }
}

.hide {
  display: none;
}
.show-list {
  display: none;
}

.menu-options {
  margin-left: 100px;
  display: none;
  @include desktop {
    display: flex;
  }
}

.menu-options ul {
  z-index: 1;
  display: flex;
}

.menu-options li {
  padding-left: 30px;
  padding-right: 30px;
  font-weight: $regular;
  font-size: 16px;
  border-right: 1px solid $black;
  &:after {
    content: "";
    color: #212121;
    display: block;
    width: 100%;
    visibility: hidden;
    margin: 0 auto;
    padding-top: 5px;
    border-bottom: 3px solid $orange;
  }
  &:hover {
    font-weight: $medium;
    &:after {
      content: "";
      color: #212121;
      display: block;
      width: 100%;
      visibility: visible;
      margin: 0 auto;
      padding-top: 5px;
      border-bottom: 3px solid $orange;
    }
  }
}

.menu-options a {
  color: $black;
}

.logo-wrapper {
  z-index: 1;
  max-width: 200px;
  align-items: center;
  display: flex;
}

.name-wrapper {
  font-weight: $bold;
  font-size: 24px;
  width: 200px;
}

.display-item {
  line-height: 3.5em;
  text-align: center;
}

.display-item a {
  color: $white;
  &:hover {
    font-weight: $medium;
  }
}

.menu-icon {
  display: inline-block;
  width: 50px;
  z-index: 1;
  @include desktop {
    display: none;
  }
}

.logo-icon {
  width: 135px;
  z-index: 1;
  display: inline-block;
}
