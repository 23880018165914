@import "../../sass/utils/index";

.product-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.product {
  width: 50%;
  display: flex;
  padding: 10px;
  margin-bottom: 15px;
  @include desktop {
    width: 20%;
    padding: 15px;
  }
}

.product-content {
  max-width: 250px;
  margin: 0 auto;
}

.product-img {
  position: relative;
  background-size: cover;
  width: 135px;
  height: 135px;
  border-radius: 100px;
  margin: 0 auto;
  border: 1px solid $black;
  @include desktop {
    width: 180px;
    height: 180px;
  }
}

.product-title {
  font-size: 16pt;
  font-weight: $regular;
  margin-top: 30px;
  text-align: center;
  // margin-bottom: 20px;
}

.product-text {
  font-size: 12pt;
  text-align: center;
}


.plus {
  border-radius: 50px;
  position: absolute;
  height: 40px;
  width: 40px;
  right: 0;
  transition: 0.7s;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F0F0F0;
  &:hover {
    background-color: #F5F5F4;
  }
  &:focus {
    background-color: #F59E27;
  }
}