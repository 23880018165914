@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto/Roboto-Regular.woff2") format("woff2"),
    url("../assets/fonts/Roboto/Roboto-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto/Roboto-Medium.woff2") format("woff2"),
    url("../assets/fonts/Roboto/Roboto-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto/Roboto-Bold.woff2") format("woff2"),
    url("../assets/fonts/Roboto/Roboto-Bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto/roboto.light.ttf") format("ttf"),
    url("../assets/fonts/Roboto/Roboto-Light.woff") format("woff");
  font-weight: 300;
}

// @font-face {
//   font-family: "Avenir Next";
//   src: url("~assets/fonts/Avenir-Next/AvenirNext-Regular.ttf")
//     format("truetype");
//   font-weight: 400;
// }

// @font-face {
//   font-family: "Avenir Next";
//   src: url("~assets/fonts/Avenir-Next/AvenirNext-Medium.ttf") format("truetype");
//   font-weight: 500;
// }

// @font-face {
//   font-family: "Avenir Next";
//   src: url("~assets/fonts/Avenir-Next/AvenirNext-Bold.ttf") format("truetype");
//   font-weight: 700;
// }
