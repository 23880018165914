@import "../../../sass/utils/index";

.footer {
  display: none;
  padding: 20px;
  color: #4f4f4f;
  font-size: 12pt;
  font-weight: $light;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #F0F0F0;
  @include desktop {
    display: block;
  }
}

.footer-logo-wrapper {
  z-index: 1;
  max-width: 200px;
  margin-right: 100px;
  display: flex;
  align-items: center
}

.footer-content {
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 100%;
  @include desktop {
    margin: 0 auto;
    max-width: 1200px;
  }
}