/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto/Roboto-Regular.woff2") format("woff2"), url("../assets/fonts/Roboto/Roboto-Regular.woff") format("woff");
  font-weight: 400; }

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto/Roboto-Medium.woff2") format("woff2"), url("../assets/fonts/Roboto/Roboto-Medium.woff") format("woff");
  font-weight: 500; }

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto/Roboto-Bold.woff2") format("woff2"), url("../assets/fonts/Roboto/Roboto-Bold.woff") format("woff");
  font-weight: 700; }

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto/roboto.light.ttf") format("ttf"), url("../assets/fonts/Roboto/Roboto-Light.woff") format("woff");
  font-weight: 300; }

/* ==========================================================================
   This file only targets tags directly and gives some basic styling to them.
   ========================================================================== */
* {
  box-sizing: border-box; }

input,
select,
textarea,
button {
  color: #363636;
  font-family: inherit;
  font-size: 18px;
  line-height: normal; }

::-webkit-input-placeholder {
  color: #F5F5F4; }

:-moz-placeholder {
  color: #F5F5F4;
  opacity: 1; }

::-moz-placeholder {
  color: #F5F5F4;
  opacity: 1; }

:-ms-input-placeholder {
  color: #F5F5F4; }

[type="search"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield; }

html {
  height: 100%; }

body {
  background-color: white;
  color: #363636;
  font-family: "Roboto", BlinkMacSystemFont, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  line-height: 1.2;
  min-height: 100%;
  position: relative; }

a {
  cursor: pointer;
  text-decoration: none; }

ul {
  list-style: none; }

strong {
  font-weight: 600; }

/* Horizontal lines */
hr {
  background: transparent;
  border: 0;
  border-bottom: #F5F5F4;
  height: 0;
  margin: 15px 0;
  overflow: hidden; }

/* Remove most spacing between table cells */
table {
  border-spacing: 0;
  border-collapse: collapse; }

td,
th {
  padding: 0; }

button {
  cursor: pointer; }
  button:focus {
    outline: 0; }

img {
  max-width: 100%;
  max-height: 100%; }
