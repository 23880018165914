@import "../../sass/utils/index";

.banner {
  margin-bottom: 40px;
}

.banner-container {
  width: 100%;
  height: 680px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @include desktop {
    margin: 0 auto;
    max-width: 1200px;
  }
}

.banner-layer {
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  color: $white;
  background-color: hsla(0,0%,20.1%,0.5);
  @include desktop {
    justify-content: flex-end;
    align-items: normal;
  }
}

.banner-content {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  @include desktop {
    flex-direction: initial;
    width: 40%;
  }
}

.banner-text {
  font-weight: $regular;
  font-size: 25px;
  letter-spacing: 1px;
  line-height: 35px;
  text-align: center;
  @include desktop {
    text-align: left;
  }
}

.banner-button-container {
  @include desktop {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
}

.banner-button {
  background-color: $orange;
  color: $white;
  transition: 0.5s;
  padding: 10px;
  font-size: 14pt;
  max-height: 55px;
  border-radius: 5px;
  &:hover {
    background-color: $light-orange;
  }
  &:focus {
    background-color: $strong-orange;
  }
  @include desktop {
    padding: 15px;
    font-size: 16pt;
  }
}