/* ==========================================================================
   This file only targets tags directly and gives some basic styling to them.
   ========================================================================== */

   * {
    box-sizing: border-box;
  }
  
  input,
  select,
  textarea,
  button {
    color: $dark;
    font-family: inherit;
    font-size: 18px;
    line-height: normal;
  }
  
  ::-webkit-input-placeholder {
    color: $grey-lighter;
  }
  
  :-moz-placeholder {
    color: $grey-lighter;
    opacity: 1;
  }
  
  ::-moz-placeholder {
    color: $grey-lighter;
    opacity: 1;
  }
  
  :-ms-input-placeholder {
    color: $grey-lighter;
  }
  
  [type="search"] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
  }
  
  html {
    height: 100%;
  }
  
  body {
    background-color: $body-background-color;
    color: $body-color;
    font-family: $body-font;
    font-size: $body-font-size;
    line-height: $body-line-height;
    min-height: 100%;
    position: relative;
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  ul {
    list-style: none;
  }
  
  strong {
    font-weight: 600;
  }
  
  /* Horizontal lines */
  
  hr {
    background: transparent;
    border: 0;
    border-bottom: $grey-lighter;
    height: 0;
    margin: 15px 0;
    overflow: hidden;
    // @include clearfix();
  }
  
  /* Remove most spacing between table cells */
  
  table {
    border-spacing: 0;
    border-collapse: collapse;
  }
  
  td,
  th {
    padding: 0;
  }
  
  button {
    cursor: pointer;
  
    &:focus {
      outline: 0;
    }
  }
  
  img {
    max-width: 100%;
    max-height: 100%;
  }
