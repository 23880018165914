@import "../../sass/utils/index";

.maps-container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #0a0a0a;
  padding-bottom: 50px;
  @include desktop {
    flex-direction: row;
  }
}

.maps {
  margin-left: 30px;
}

.map {
  padding: 20px;
}

.address {
  margin-top: 20px;
  margin-left: 8px;
  margin-bottom: 20px;
}

.addresses-container {
  padding-top: 50px;
}

.fact-container {
  margin-top: 30px;
}

.addresses-container .address-list {
  list-style-type: disc;
}

.bold {
  font-weight: 700;
}
