@import "../../../sass/utils/index";

.content {
  padding-top: 100px;
  padding-bottom: 140px;
}

.content-wrapper {
  padding: 20px;
  padding-bottom: 0;
  width: 100%;
  @include desktop {
    margin: 0 auto;
    max-width: 1200px;
  }
}
