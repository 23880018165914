@import "../../sass/utils/index";

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  @include desktop {
    width: 50%;
    border-right: 1px solid #0a0a0a;
    padding-right: 30px;
  }
}

.info-contact {
  margin-top: 20px;
  margin-bottom: 20px;
}

.label-form {
  font-size: 14pt;
  font-weight: 400;
  margin: 8px;
}

.input-item {
  margin-top: 15px;
}

.contact-form-container {
  border-bottom: 1px solid #0a0a0a;
  padding-bottom: 20px;
}

.contact-button {
  background-color: $grey-light;
  color: $black;
  transition: 0.5s;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 12pt;
  border-radius: 5px;
  &:hover {
    background-color: $grey-lighter;
  }
  &:focus {
    background-color: $grey;
  }
  @include desktop {
    font-size: 14pt;
  }
}

.contact-button-container {
  display: flex;
  width: 100%;
  padding-top: 20px;
  justify-content: flex-end;
}

.email-container {
  display: flex;
  padding-top: 40px;
}

.email-container .label-form {
  padding-right: 40px;
}

.email-text {
  padding-top: 3px;
  margin: 8px;
}
