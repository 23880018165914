/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
@font-face {
  font-family: "Roboto";
  src: url(/static/media/Roboto-Regular.665b409f.woff2) format("woff2"), url(/static/media/Roboto-Regular.01c72916.woff) format("woff");
  font-weight: 400; }

@font-face {
  font-family: "Roboto";
  src: url(/static/media/Roboto-Medium.4f16031a.woff2) format("woff2"), url(/static/media/Roboto-Medium.d30cc4f7.woff) format("woff");
  font-weight: 500; }

@font-face {
  font-family: "Roboto";
  src: url(/static/media/Roboto-Bold.213e41ae.woff2) format("woff2"), url(/static/media/Roboto-Bold.5dbd6798.woff) format("woff");
  font-weight: 700; }

@font-face {
  font-family: "Roboto";
  src: url(/static/media/roboto.light.46e48ce0.ttf) format("ttf"), url(/static/media/Roboto-Light.3b813c2a.woff) format("woff");
  font-weight: 300; }

/* ==========================================================================
   This file only targets tags directly and gives some basic styling to them.
   ========================================================================== */
* {
  box-sizing: border-box; }

input,
select,
textarea,
button {
  color: #363636;
  font-family: inherit;
  font-size: 18px;
  line-height: normal; }

::-webkit-input-placeholder {
  color: #F5F5F4; }

:-moz-placeholder {
  color: #F5F5F4;
  opacity: 1; }

::-moz-placeholder {
  color: #F5F5F4;
  opacity: 1; }

:-ms-input-placeholder {
  color: #F5F5F4; }

[type="search"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield; }

html {
  height: 100%; }

body {
  background-color: white;
  color: #363636;
  font-family: "Roboto", BlinkMacSystemFont, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  line-height: 1.2;
  min-height: 100%;
  position: relative; }

a {
  cursor: pointer;
  text-decoration: none; }

ul {
  list-style: none; }

strong {
  font-weight: 600; }

/* Horizontal lines */
hr {
  background: transparent;
  border: 0;
  border-bottom: #F5F5F4;
  height: 0;
  margin: 15px 0;
  overflow: hidden; }

/* Remove most spacing between table cells */
table {
  border-spacing: 0;
  border-collapse: collapse; }

td,
th {
  padding: 0; }

button {
  cursor: pointer; }
  button:focus {
    outline: 0; }

img {
  max-width: 100%;
  max-height: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
.header {
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: white;
  position: fixed;
  height: 80px; }

.scrollable {
  z-index: 1; }

.header-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 20px;
  padding-bottom: 0;
  width: 100%;
  border-bottom: 1px solid #0a0a0a;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media screen and (min-width: 1000px) {
    .header-content {
      margin: 0 auto;
      max-width: 1200px;
      -webkit-justify-content: space-between;
              justify-content: space-between; } }

.menu-button {
  border: none; }

.display-menu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: absolute;
  right: 0;
  top: 80px;
  width: 135px;
  background-color: rgba(0, 0, 0, 0.4);
  visibility: visible;
  opacity: 1;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  transition-delay: 0s;
  transition: visibility 0s, opacity 0.5s linear; }
  .display-menu.hide {
    visibility: hidden;
    opacity: 0; }
  @media screen and (min-width: 1000px) {
    .display-menu {
      visibility: hidden; } }

.mobile-menu {
  display: block; }
  @media screen and (min-width: 1000px) {
    .mobile-menu {
      display: none; } }

.hide {
  display: none; }

.show-list {
  display: none; }

.menu-options {
  margin-left: 100px;
  display: none; }
  @media screen and (min-width: 1000px) {
    .menu-options {
      display: -webkit-flex;
      display: flex; } }

.menu-options ul {
  z-index: 1;
  display: -webkit-flex;
  display: flex; }

.menu-options li {
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 400;
  font-size: 16px;
  border-right: 1px solid #0a0a0a; }
  .menu-options li:after {
    content: "";
    color: #212121;
    display: block;
    width: 100%;
    visibility: hidden;
    margin: 0 auto;
    padding-top: 5px;
    border-bottom: 3px solid #F59E27; }
  .menu-options li:hover {
    font-weight: 500; }
    .menu-options li:hover:after {
      content: "";
      color: #212121;
      display: block;
      width: 100%;
      visibility: visible;
      margin: 0 auto;
      padding-top: 5px;
      border-bottom: 3px solid #F59E27; }

.menu-options a {
  color: #0a0a0a; }

.logo-wrapper {
  z-index: 1;
  max-width: 200px;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex; }

.name-wrapper {
  font-weight: 700;
  font-size: 24px;
  width: 200px; }

.display-item {
  line-height: 3.5em;
  text-align: center; }

.display-item a {
  color: white; }
  .display-item a:hover {
    font-weight: 500; }

.menu-icon {
  display: inline-block;
  width: 50px;
  z-index: 1; }
  @media screen and (min-width: 1000px) {
    .menu-icon {
      display: none; } }

.logo-icon {
  width: 135px;
  z-index: 1;
  display: inline-block; }

/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
.content {
  padding-top: 100px;
  padding-bottom: 140px; }

.content-wrapper {
  padding: 20px;
  padding-bottom: 0;
  width: 100%; }
  @media screen and (min-width: 1000px) {
    .content-wrapper {
      margin: 0 auto;
      max-width: 1200px; } }

/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
.footer {
  display: none;
  padding: 20px;
  color: #4f4f4f;
  font-size: 12pt;
  font-weight: 300;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #F0F0F0; }
  @media screen and (min-width: 1000px) {
    .footer {
      display: block; } }

.footer-logo-wrapper {
  z-index: 1;
  max-width: 200px;
  margin-right: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.footer-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 20px;
  width: 100%; }
  @media screen and (min-width: 1000px) {
    .footer-content {
      margin: 0 auto;
      max-width: 1200px; } }

/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.title {
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 16pt; }
  @media screen and (min-width: 1000px) {
    .title {
      font-size: 24pt; } }

.paragraph {
  font-size: 16pt;
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: 1px;
  line-height: 35px;
  font-weight: 300; }

.container {
  width: 100%;
  padding: 20px; }
  @media screen and (min-width: 1000px) {
    .container {
      margin: 0 auto;
      display: block;
      max-width: 1200px; } }

/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
.banner {
  margin-bottom: 40px; }

.banner-container {
  width: 100%;
  height: 680px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  @media screen and (min-width: 1000px) {
    .banner-container {
      margin: 0 auto;
      max-width: 1200px; } }

.banner-layer {
  padding: 20px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  color: white;
  background-color: rgba(51, 51, 51, 0.5); }
  @media screen and (min-width: 1000px) {
    .banner-layer {
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      -webkit-align-items: normal;
              align-items: normal; } }

.banner-content {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  @media screen and (min-width: 1000px) {
    .banner-content {
      -webkit-flex-direction: initial;
              flex-direction: initial;
      width: 40%; } }

.banner-text {
  font-weight: 400;
  font-size: 25px;
  letter-spacing: 1px;
  line-height: 35px;
  text-align: center; }
  @media screen and (min-width: 1000px) {
    .banner-text {
      text-align: left; } }

@media screen and (min-width: 1000px) {
  .banner-button-container {
    position: absolute;
    bottom: 15px;
    right: 15px; } }

.banner-button {
  background-color: #F59E27;
  color: white;
  transition: 0.5s;
  padding: 10px;
  font-size: 14pt;
  max-height: 55px;
  border-radius: 5px; }
  .banner-button:hover {
    background-color: #FEB44F; }
  .banner-button:focus {
    background-color: #FE9300; }
  @media screen and (min-width: 1000px) {
    .banner-button {
      padding: 15px;
      font-size: 16pt; } }

/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
.product-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.product {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  padding: 10px;
  margin-bottom: 15px; }
  @media screen and (min-width: 1000px) {
    .product {
      width: 20%;
      padding: 15px; } }

.product-content {
  max-width: 250px;
  margin: 0 auto; }

.product-img {
  position: relative;
  background-size: cover;
  width: 135px;
  height: 135px;
  border-radius: 100px;
  margin: 0 auto;
  border: 1px solid #0a0a0a; }
  @media screen and (min-width: 1000px) {
    .product-img {
      width: 180px;
      height: 180px; } }

.product-title {
  font-size: 16pt;
  font-weight: 400;
  margin-top: 30px;
  text-align: center; }

.product-text {
  font-size: 12pt;
  text-align: center; }

.plus {
  border-radius: 50px;
  position: absolute;
  height: 40px;
  width: 40px;
  right: 0;
  transition: 0.7s;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #F0F0F0; }
  .plus:hover {
    background-color: #F5F5F4; }
  .plus:focus {
    background-color: #F59E27; }

.color-container {
  margin: 10px; }

.color-content {
  border-radius: 50px;
  border: 1px solid #0a0a0a;
  width: 17px;
  height: 17px; }

/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
.modal {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 600ms ease-in;
  z-index: 1; }
  .modal.visible {
    display: block;
    opacity: 1;
    pointer-events: auto;
    z-index: 99999; }
  .modal .modal-container {
    background-color: white;
    border-radius: 15px;
    height: 100%;
    position: relative;
    margin: 0 auto;
    padding: 3em;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
    @media screen and (min-width: 1000px) {
      .modal .modal-container {
        overflow-y: auto;
        height: auto;
        margin: 5% auto;
        max-height: 57em;
        max-width: 66em;
        width: 85%; } }
    .modal .modal-container .banner-layer {
      border: none;
      width: 100%;
      height: 100%; }
  .modal .close {
    background-color: white;
    color: #0a0a0a;
    font-size: 40px;
    padding-right: 10px;
    position: absolute;
    right: 0;
    border: none;
    text-align: center;
    text-decoration: none;
    border-top-right-radius: 15px;
    top: 0;
    z-index: 1; }

.color-container {
  display: -webkit-flex;
  display: flex; }

.modal-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 15px;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media screen and (min-width: 1000px) {
    .modal-content {
      -webkit-align-items: flex-start;
              align-items: flex-start;
      width: 50%; } }

.banner-imgs-container {
  padding: 20px; }
  @media screen and (min-width: 1000px) {
    .banner-imgs-container {
      -webkit-flex-direction: initial;
              flex-direction: initial;
      width: 50%; } }

.modal-layer {
  padding: 20px;
  padding-top: 0;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%; }
  @media screen and (min-width: 1000px) {
    .modal-layer {
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-align-items: normal;
              align-items: normal; } }

img.modal-img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 450px; }

.img-container {
  max-width: 400px;
  border: 1px solid #0a0a0a; }

.modal-title {
  font-weight: 400;
  font-size: 16pt;
  text-align: center; }
  @media screen and (min-width: 1000px) {
    .modal-title {
      padding-left: 40px;
      font-size: 24pt;
      text-align: start; } }

/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
.news-container {
  width: 100%;
  padding: 15px; }
  @media screen and (min-width: 1000px) {
    .news-container {
      margin: 0 auto;
      display: block;
      padding: 20px;
      max-width: 1200px; } }

/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
.img-section-layer {
  padding: 20px;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: rgba(51, 51, 51, 0.5);
  color: white; }
  @media screen and (min-width: 1000px) {
    .img-section-layer {
      -webkit-align-items: normal;
              align-items: normal; } }

@media screen and (min-width: 1000px) {
  .img-section-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; } }

.banner-container.img-section-container {
  height: auto; }

/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
.no-img-content {
  text-align: center;
  padding-bottom: 50px;
  padding-top: 50px; }

/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
.process {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  padding: 15px;
  margin-bottom: 15px; }
  @media screen and (min-width: 1000px) {
    .process {
      width: 33%; } }

.process-content {
  margin: 0 auto;
  box-shadow: 2px 11px 11px -3px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  width: 270px;
  border-radius: 25px; }

.process-img {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  height: 270px;
  background-position: center;
  background-size: cover; }

.process-title {
  position: absolute;
  left: 0;
  margin: 0 auto;
  right: 0;
  background-color: #F5F5F4;
  left: 0;
  border-radius: 20px;
  width: 165px;
  padding: 8px;
  top: -20px;
  font-size: 16pt;
  font-weight: 400;
  text-align: center; }

.process-info-container {
  position: relative;
  padding: 10px;
  padding-top: 30px;
  padding-bottom: 30px; }

/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */

/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
.option-text {
  font-size: 12pt;
  font-weight: 400; }
  @media screen and (min-width: 1000px) {
    .option-text {
      font-size: 16pt; } }

.radio-btn-group {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 5px;
  margin-right: 25px; }

.radio-btn {
  width: 20px;
  height: 20px;
  border: 1px solid #0a0a0a;
  margin-top: auto;
  margin-right: 10px;
  border-radius: 35px;
  margin-bottom: auto;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex; }

.checked .dot {
  width: 12px;
  height: 12px;
  background-color: #0a0a0a;
  border-radius: 17px; }

/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
.categories-nav {
  display: none;
  margin-bottom: 20px; }
  @media screen and (min-width: 1000px) {
    .categories-nav {
      display: -webkit-flex;
      display: flex; } }

.nav-responsive {
  position: absolute;
  background-color: white;
  display: none;
  z-index: 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  top: 40px;
  width: 175px;
  right: -36px; }
  .nav-responsive.nav-show {
    display: -webkit-flex;
    display: flex; }

.filter-container {
  display: -webkit-flex;
  display: flex;
  position: relative; }
  @media screen and (min-width: 1000px) {
    .filter-container {
      display: none; } }

.title-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
.contact-form {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media screen and (min-width: 1000px) {
    .contact-form {
      width: 50%;
      border-right: 1px solid #0a0a0a;
      padding-right: 30px; } }

.info-contact {
  margin-top: 20px;
  margin-bottom: 20px; }

.label-form {
  font-size: 14pt;
  font-weight: 400;
  margin: 8px; }

.input-item {
  margin-top: 15px; }

.contact-form-container {
  border-bottom: 1px solid #0a0a0a;
  padding-bottom: 20px; }

.contact-button {
  background-color: #F0F0F0;
  color: #0a0a0a;
  transition: 0.5s;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 12pt;
  border-radius: 5px; }
  .contact-button:hover {
    background-color: #F5F5F4; }
  .contact-button:focus {
    background-color: #E0E0E0; }
  @media screen and (min-width: 1000px) {
    .contact-button {
      font-size: 14pt; } }

.contact-button-container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  padding-top: 20px;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.email-container {
  display: -webkit-flex;
  display: flex;
  padding-top: 40px; }

.email-container .label-form {
  padding-right: 40px; }

.email-text {
  padding-top: 3px;
  margin: 8px; }

/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
.maps-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-bottom: 1px solid #0a0a0a;
  padding-bottom: 50px; }
  @media screen and (min-width: 1000px) {
    .maps-container {
      -webkit-flex-direction: row;
              flex-direction: row; } }

.maps {
  margin-left: 30px; }

.map {
  padding: 20px; }

.address {
  margin-top: 20px;
  margin-left: 8px;
  margin-bottom: 20px; }

.addresses-container {
  padding-top: 50px; }

.fact-container {
  margin-top: 30px; }

.addresses-container .address-list {
  list-style-type: disc; }

.bold {
  font-weight: 700; }

/* Import all from commons */
/* Colors */
/* Brand */
/* Responsiveness */
/* Fonts */
/* Body */
.contact-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media screen and (min-width: 1000px) {
    .contact-container {
      -webkit-flex-direction: row;
              flex-direction: row; } }

.logo-drawer {
  width: 200px;
  padding: 20px; }

.button-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.button-container .button-form {
  margin: 20px; }

.drag-zone {
  border: 1px solid #0a0a0a;
  min-height: 60px;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer; }

.color-picker {
  display: -webkit-flex;
  display: flex; }

