@import "../../sass/utils/index";

.modal {
  background:rgba(0,0,0,0.5);
  bottom:0;
  left:0;
  opacity:0;
  pointer-events:none;
  position:fixed;
  right:0;
  top:0;
  -webkit-transition: opacity 600ms ease-in;
  -moz-transition: opacity 600ms ease-in;
  transition: opacity 600ms ease-in;
  z-index:1;
  &.visible {
    display: block;
    opacity:1;
    pointer-events:auto;
    z-index:99999;
  }
  .modal-container {
    background-color:white;
    border-radius: 15px;
    height: 100%;
    position:relative;
    margin:0 auto;
    padding:3em;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    @include desktop {
      overflow-y: auto;
      height: auto;
      margin:5% auto;
      max-height: 57em;
      max-width:66em;
      width:85%;
      }
    .banner-layer {
        border:none;
        width:100%;
        height:100%;
    }
  }
  .close {
    background-color: $white;
    color: $black;
    font-size: 40px;
    padding-right: 10px;
    position:absolute;
    right:0;
    border: none;
    text-align:center;
    text-decoration:none;
    border-top-right-radius: 15px;
    top:0;
    z-index: 1;
  }
}

.color-container {
  display: flex;
}

.modal-content {
  display: flex;
  align-items: center;
  padding: 15px;
  flex-direction: column;
  @include desktop {
    align-items: flex-start;
    width: 50%;
  }
}

.banner-imgs-container {
  padding: 20px;
  @include desktop {
    flex-direction: initial;
    width: 50%;
  }
}

.modal-layer {
  padding: 20px;
  padding-top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  @include desktop {
    justify-content: flex-end;
    flex-direction: row;
    align-items: normal;
  }
}

img.modal-img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 450px;

}

.img-container {
  max-width: 400px;
  border: 1px solid $black;
}

.modal-title {
  font-weight: $regular;
  font-size: 16pt;
  text-align: center;
  @include desktop {
    padding-left: 40px;
    font-size: 24pt;
    text-align: start;
  }
}
