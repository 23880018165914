@import "../../sass/utils/index";

.process {
    width: 100%;
    display: flex;
    padding: 15px;
    margin-bottom: 15px;
    @include desktop {
      width: 33%;
    }
  }

.process-content {
  margin: 0 auto;
  box-shadow: 2px 11px 11px -3px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  width: 270px;
  border-radius: 25px;
}

.process-img {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  height: 270px;
  background-position: center;
  background-size: cover;
}

.process-title {
  position: absolute;
  left: 0;
  margin: 0 auto;
  right: 0;
  background-color: #F5F5F4;
  left: 0;
  border-radius: 20px;
  width: 165px;
  padding: 8px;
  top: -20px;
  font-size: 16pt;
  font-weight: 400;
  text-align: center;
}

.process-info-container {
  position: relative;
  padding: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
}
