@import "../../../sass/utils/index";

.news-container {
  width: 100%;
  padding: 15px;
  @include desktop {
    margin: 0 auto;
    display: block;
    padding: 20px;
    max-width: 1200px;
  }
}
