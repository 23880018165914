@import "../../../sass/utils/index";

.categories-nav {
  display: none;
  margin-bottom: 20px;
  @include desktop {
    display: flex;
  }
}

.nav-responsive {
  position: absolute;
  background-color: white;
  display: none;
  z-index: 1;
  flex-direction: column;
  top: 40px;
  width: 175px;
  right: -36px;
  &.nav-show {
    display: flex;
  }
}

.filter-container {
  display: flex;
  position: relative;
  @include desktop {
    display: none;
  }
}

.title-container {
  display: flex;
  justify-content: space-between;
}
