@import "../../sass/utils/index";

.img-section-layer {
    padding: 20px;
    width: 100%;
    align-items: center;
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: hsla(0,0%,20.1%,0.5);
    color: $white;
    @include desktop {
      // justify-content: flex-end;
      align-items: normal;
    }
  }

.img-section-wrapper {
  @include desktop {
    display: flex;
    justify-content: flex-end;
  }
}

.banner-container.img-section-container {
  height: auto;
}