@import "./sass/utils/index";


.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title {
  font-weight: $regular;
  margin-bottom: 20px;
  font-size: 16pt;
  @include desktop {
    font-size: 24pt;
  }
}

.paragraph {
  font-size: 16pt;
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: 1px;
  line-height: 35px;
  font-weight: $light;
}

.container {
  width: 100%;
  padding: 20px;
  @include desktop {
    margin: 0 auto;
    display: block;
    max-width: 1200px;
  }
}
